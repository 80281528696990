import { useEffect, useState } from "react";
import f from "./firebase";
import { getFirestore, collection, onSnapshot, setDoc, doc, updateDoc, addDoc } from "firebase/firestore";
import "./statements.css"

function Statement() {
  const [data, setData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    const db = getFirestore(f);
    const unsubscribe = onSnapshot(collection(db, "statements-transformed"), (querySnapshot) => {
      let data = {};
      let maxMonth = null;
      querySnapshot.forEach((doc) => {
        let statementDateParsed = Date.parse(doc.data().statementDate);
        let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(statementDateParsed);
        let month = new Intl.DateTimeFormat('en', {month: 'long'}).format(statementDateParsed);
        let monthNumeric = new Intl.DateTimeFormat('en', {month: 'numeric'}).format(statementDateParsed);
        if (monthNumeric.length == 1) {
          monthNumeric = "0" + monthNumeric;
        }
        let yearMonth = year + "-" + monthNumeric + " " + month;
        data[yearMonth] = data[yearMonth] || {};
        let accountName = doc.data().accountType + " " + doc.data().accountNumber;
        data[yearMonth][accountName] = doc.data();
        if (!maxMonth || (yearMonth > maxMonth)) {
          maxMonth = yearMonth;
        }
      });
      setData(data);
      setSelectedMonth(maxMonth);
      setSelectedAccount(Object.keys(data[maxMonth])[0]);
    });
    return unsubscribe;
  }, []);

  const setSelectedMonth2 = (month) => {
    if (!data[month][selectedAccount]) {
      setSelectedAccount(Object.keys(data[month])[0]);
    }
    setSelectedMonth(month);
  };

  const sortedKeys = (entries) => {
    let keys = Object.keys(entries);
    keys.sort();
    return keys;
  }

  return (
    <div>
      <h2>Statements</h2>
      <select id="statementMonth" onChange={(e) => setSelectedMonth2(e.target.value)}>
        {sortedKeys(data).map((yearMonth) => (<option key={yearMonth} value={yearMonth} selected={yearMonth==selectedMonth ? 'selected' : ''}>{yearMonth}</option>))}
      </select>
      <select id="account" onChange={(e) => setSelectedAccount(e.target.value)}>
        {selectedMonth && Object.keys(data[selectedMonth]).map((accountName) => 
          (<option key={accountName} value={accountName} selected={accountName==selectedAccount ? 'selected' : ''}>{accountName}</option>))}
      </select><br/><br/>
      <hr/>
      <br/>
      {selectedMonth && selectedAccount && (<div>
        {data[selectedMonth][selectedAccount].accountType} {data[selectedMonth][selectedAccount].accountNumber}<br/>
        Statement Date: {data[selectedMonth][selectedAccount].statementDate}<br/>
        Due Date: {data[selectedMonth][selectedAccount].dueDate}<br/>
        {data[selectedMonth][selectedAccount].cards ? data[selectedMonth][selectedAccount].cards.map((card) => (
        <table style={{border: "1px solid black"}}>
          <tr><th width={100}></th><th width={400} style={{border: "1px solid black"}}>{card.card}</th><th width={400} style={{border: "1px solid black"}}>{card.subTotal}</th></tr>
          {card.transactions ? card.transactions.map((txn) => (
            <tr><td style={{border: "1px solid black"}}>{txn.date}</td><td style={{border: "1px solid black"}}>{txn.description}</td><td style={{border: "1px solid black"}}>{txn.notes}</td><td style={{border: "1px solid black"}}>{txn.amount}</td></tr>
          )) : null}
        </table>)) : <>No transactions</>}
      </div>)}
    </div>
  );
}

export default Statement;

// db.collection("statements-transformed").get().then((querySnapshot) => {
//   window.dataRaw = querySnapshot;
//   let monthSelector = document.getElementById('statementMonth');
//   monthSelector.innerHTML = '';
//   window.data = {};
//   for (let doc of querySnapshot.docs) {
//     let yearMonth = new Intl.DateTimeFormat('en', {year: 'numeric', month: 'long' })
//         .format(Date.parse(doc.data().statementDate));
//     if (!(yearMonth in window.data)) {
//       window.data[yearMonth] = {};
//     }
//     let accountName = doc.data().accountType + " " + doc.data().accountNumber;
//     if (!(accountName in window.data[yearMonth])) {
//       window.data[yearMonth][accountName] = doc.data();
//     }
//   }  
//   for (let yearMonth in window.data) {
//     let option = document.createElement('option');
//     option.text = yearMonth;
//     option.value = yearMonth;
//     monthSelector.add(option);
//   }  